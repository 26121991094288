export const appSettingModel = () => {
    return {
        apiURL:{
            "127.0.0.1":"https://api.gametime.local/api/v1",
            "localhost":"https://api.gametime.local/api/v1",
            "10.0.0.94":"https://api.gametime.local/api/v1",
            "officialgametimeapp.local":"https://api.gametime.local/api/v1",
            "master.d2r69k27iu1uof.amplifyapp.com":"https://api.seeandplaymode.com/api/v1",
            "app.seeandplaymode.com":"https://api.seeandplaymode.com/api/v1",
            "development.d2r69k27iu1uof.amplifyapp.com":"https://api.seeandplaymode.com/api/v1"

        },
        storageURL:{
            "officialgametimeapp.local":"https://media.officialgametimeapp.local",
            "localhost":"https://media.officialgametimeapp.local",
            "master.d2r69k27iu1uof.amplifyapp.com":"https://api.gametime.local/hostedmedia",
            "app.seeandplaymode.com":"https://api.gametime.local/hostedmedia",
            "development.d2r69k27iu1uof.amplifyapp.com":"https://api.gametime.local/hostedmedia"
        },
        appURL:{},
        wsURL:{
            "localhost":{domain:"ws.seeandplaymode.com",port:8581,secure:false},
            "gametime.local":{domain:"api.gametime.local",port:8553,secure:true},
            "gametime.purposefultechnologies.com":{domain:"gametime.purposefultechnologies.com",port:8553,secure:true},
            "10.0.0.94":{domain:"api.gametime.local",port:8553,secure:true},
            "127.0.0.1":{domain:"api.gametime.local",port:8581,secure:false},
            "officialgametime.local":{domain:"officialgametimeapp.local",port:8553,secure:true},
            "officialgametimeapp.local":{domain:"ws.seeandplaymode.com",port:8553,secure:true},
            "master.d2r69k27iu1uof.amplifyapp.com":{domain:"api.seeandplaymode.com",port:8553,secure:true},
            "app.seeandplaymode.com":{domain:"ws.seeandplaymode.com",port:8553,secure:true},
            "development.seeandplaymode.com":{domain:"ws.seeandplaymode.com",port:8553,secure:true},
            "development.d2r69k27iu1uof.amplifyapp.com":{domain:"ws.seeandplaymode.com",port:8553,secure:true}
        },
        URLStruct:{
            "localhost":"http://officialgametimeapp.local",
            "http://officialgametime.local":"http://officialgametimeapp.local",
            "http://officialGameTimeApp.local":"http://officialGameTimeApp.local"
        },
        "isLoggedin":false,
        "currentModule":null,
        "showSpinner":false,
        "defaultModule": "components/gametime/FrontPage/FrontPage",
        "defaultLoggedInModule": "components/gametime/MyGameTime/MyGameTime",
    }
};
