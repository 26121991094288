export const updateWebSocket = (data) =>{
    return {
        type:"UPDATE_WEB_SOCKET",
        payload:data
    }
};

export const addWebSocketListener = (data) => {
    return {
        type:"ADD_WEB_SOCKET_LISTENER",
        payload:data
    }
};
