const testingReducer = (state={},action) => {
    if (action.type === 'UPDATE_RANDOM_LOAD') {
        return  Object.assign({}, state, action.payload);
    }
    return state;
};




export default {
    testing:testingReducer
}
