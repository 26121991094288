import {userModel} from '../datamodels/permissions'

const userReducer = (state=userModel(),action) => {
    if (action.type === "UPDATE_CURRENT_USER") {
        return Object.assign({}, state, action.payload);
    }

    if(action.type==='LOGOUT_USER'){
        return Object.assign({},userModel());
    }

    return state;
};


export default {
    user:userReducer
}
