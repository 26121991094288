import axios from "axios";

export const apiURL = (props) =>{
    //eslint-disable-next-line
    return props?.appSettings?.apiURL?.hasOwnProperty(self.location.hostname) ? props?.appSettings.apiURL[self.location.hostname] : '';
};

export const makeFormData = (data) => {
    let former=new FormData();
    for(var x in data){
        former.append(x,data[x]);
    }
    return former;
};

export const obtainUser = (props)=>{
    return props.user ? props.user.token : '';
};

export const obtainProjects = (props,callBack,fail) => {
    axios.get(apiURL(props) + "/visualizations/projects/",{headers: {
            "Authorization": "Bearer " + obtainUser(props)
        }}).then((evt)=>callBack(props,evt)).catch((evt)=>fail(props,evt));
};

export const obtainProjectFiles = (props,callBack,fail) => {
    axios.get(apiURL(props) + "/visualizations/projects/" + props.visualizer.currentProject.projectuuid + "/files",{headers: {
            "Authorization": "Bearer " + obtainUser(props)
        }}).then((evt)=>callBack(props,evt)).catch((evt)=>fail(props,evt));
};

export const obtainProjectStats = (props,callBack,fail) => {
    axios.get(apiURL(props) + "/visualizations/projects/" + props.visualizer.currentProject.projectuuid + '/stats',{headers: {
            "Authorization": "Bearer " + obtainUser(props)
        }}).then((evt)=>callBack(props,evt)).catch((evt)=>fail(props,evt));
};

export const auditProjectFiles = (props,callBack,fail) => {
    axios.get(apiURL(props) + "/visualizations/files/" + props.visualizer.currentProject.projectuuid + '/audit',{headers: {
            "Authorization": "Bearer " + obtainUser(props)
        }}).then((evt)=>callBack(props,evt)).catch((evt)=>fail(props,evt));
};

export const verifySiteFile = (props,filename,callBack,fail) => {
    axios.get(apiURL(props) + "/visualizations/file/test?filename=" + filename,{headers:{
            "Authorization": "Bearer " + obtainUser(props)
        }}).then((evt)=>callBack(props,evt)).catch((evt)=>fail(props,evt));
};
