/* eslint-disable */
import React from 'react';
//import {updateWebSocket} from 'src/actions/websocketActions';
import {CFWebSocket} from './cf/cfwebsocketChannel';
import * as gameTimeActions from "src/actions/gameTime";
import store from 'src/store';
import {updateWebSocket} from "../../actions/websocketActions";
import * as eyesOnActions from "../../actions/eyesonActions";

let clientId = null;
let chatChannel = '';

const subscriptionModel=()=>{
    return {subscribed:-1}
};
let subscriptions={};

let mapper={
    "gameTimeActions":gameTimeActions,
    "eyesOnActions":eyesOnActions
};

const CFWS = CFWebSocket;

const applicationID="websocketland";
/*
const URLStruct={
    "localhost":{domain:"officialgametimeapp.local",port:8581,secure:false},
    "gametime.local":{domain:"officialgametimeapp.local",port:8553,secure:true},
    "gametime.purposefultechnologies.com":{domain:"gametime.purposefultechnologies.com",port:8553,secure:true},
    "10.0.0.94":{domain:"officialgametimeapp.local",port:8553,secure:true},
    "127.0.0.1":{domain:"officialgametimeapp.local",port:8581,secure:false},
    "officialgametime.local":{domain:"officialgametimeapp.local",port:8581,secure:false},
    "officialgametimeapp.local":{domain:"officialgametimeapp.local",port:8553,secure:true},
    "master.d2r69k27iu1uof.amplifyapp.com":{domain:"api.seeandplaymode.com",port:8553,secure:true},
    "app.seeandplaymode.com":{domain:"ws.seeandplaymode.com",port:8553,secure:true},
    "development.seeandplaymode.com":{domain:"ws.seeandplaymode.com",port:8553,secure:true},
};
*/
export const createWebSocket = (props) =>{
    let wsStruct = props.appSettings.wsURL.hasOwnProperty(self.location.hostname) ? props.appSettings.wsURL[self.location.hostname] : '';
    //console.dir("wsstruct");
    //console.dir(wsStruct);
    return CFWS.init(
        'gameTime',
        applicationID,
        false,
        '4102AE99E1A70A12D900FC4BC802DEBB',
        '',
        (evt) => defaultmsgHandler(evt),
        (evt) => openHandler(evt),
        (evt) => openClose(evt),
        (evt) => errorHandler(evt),
        self.location.pathname,
        wsStruct.secure,
        wsStruct.domain,
        wsStruct.port
    );
};
/*
const webSocket = CFWS.init(
    'gameTime',
    applicationID,
    false,
    '4102AE99E1A70A12D900FC4BC802DEBB',
    '',
    (evt) => defaultmsgHandler(evt),
    (evt) => openHandler(evt),
    (evt) => openClose(evt),
    (evt) => errorHandler(evt),
    self.location.pathname,
    URLStruct.hasOwnProperty(self.location.hostname) ? URLStruct[self.location.hostname].secure : '',
    URLStruct.hasOwnProperty(self.location.hostname) ? URLStruct[self.location.hostname].domain : '',
    URLStruct.hasOwnProperty(self.location.hostname) ? URLStruct[self.location.hostname].port: ''
);
*/
export const subscribeChannel = (props,channel,data) => {
    let ourChannel=channel.split('.')[1];
    //let currentStore = store.getState();
    let subs = Object.assign({},props.webSocketData.subscriptions);
    subs[ourChannel]={subscribed:2};
    updateWebSocketData({subscriptions:subs});
    props.webSocketData.websocket.subscribe(channel, data);
};

export const unsubscribeChannel = (props,channel) => {
    let ourChannel=channel.split('.')[1];
    let currentStore = store.getState();
    let subs= Object.assign(currentStore.webSocketData.subscriptions);
    subs[ourChannel]={subscribed:0};
    updateWebSocketData({subscriptions:subs});
    currentStore.webSocketData.websocket.unsubscribe(channel);

};

export const getSubscriptions = (callback) => {
    let currentStore = store.getState();
    currentStore.webSocketData.websocket.getSubscriptions((e)=>{syncSubscriptions(e); if(callback){callback(props,e)}});
};

const syncSubscriptions = (e) => {

    let newsubscriptions={};
    e.channels.map((channel)=>{
        let item = channel.split('.');
        newsubscriptions[item[1]]={subscribed:1}
    });
    subscriptions=newsubscriptions;
    updateWebSocketData({subscriptions:newsubscriptions});
    requestChannelUpdate(newsubscriptions);
};

export const getSubscriberCount = (channel,data) => {
    let currentStore = store.getState();
    currentStore.webSocketData.websocket.getSubscriberCount(channel, data);
};



export const publishMessage = (channel,message) => {
    if(channel) {
        let currentStore = store.getState();
        currentStore.webSocketData.websocket.publish(channel, message);
    }
};

export const requestChannelUpdate = (subscriptions) => {
    for(let sub in subscriptions){
        if(subscriptions[sub].subscribed===1) {
            let timer=new Date();
            publishMessage(`games.` + sub, {
                scope: "gameTimeActions",
                method: "requestSocketUpdate",
                message: {client: clientId, time: timer.getTime()}
            });
        }
    }
    //
};


export const createPublishAnnouncement = (scopeName,funcName,message)=>{
    return {scope:scopeName,method:funcName,message:message,originalSender:''}
};



const defaultmsgHandler= (evt) => {
    switch (evt.type) {
        case "data":
            if(shouldReceive(evt)) {
                let func = mapper[evt.data.scope];
                let msg = Object.assign({},evt.data.message);
                delete msg["originalSender"];
                let returnedAction = func[evt.data.method](msg);
                //console.dir("received event being dispatched");
                //console.dir(returnedAction);
                if(returnedAction) {
                    store.dispatch(returnedAction);
                }
            }
            break;
        case "response":
            processResponse(evt);
            break;
        default:
            console.dir("No type of event");
            console.dir(evt);
            break;
    }
};

const shouldReceive = (evt) =>{
    //console.dir("SHould Receive");
    //console.dir(evt);
    if(clientId && evt.hasOwnProperty("publisherid") &&
        parseInt(evt.publisherid.trim()) === parseInt(clientId.trim())){
        //console.dir("no");
        return false;
    }
    //console.dir("event made it through");
    //console.dir(evt);
    return true;
};


const processResponse = (evt ) => {
    //console.dir("in Process WebSocket Response");
    //console.dir(evt);
    if(evt.reqType==='subscribe'){
        if(evt.msg==='ok'){
            getSubscriptions()
        }

    }
    else if(evt.reqType==='unsubscribe'){
        if(evt.msg==='ok'){
            getSubscriptions()
        }
    }
    else if(evt.reqType==='welcome'){
        setClientid(evt.clientid);
    }
    else if(evt.reqType==='publish'){

    }
    else if(evt.reqType==='getSubscriberCount'){
        //props.updateSession({channel:evt.channel,count:evt.subscriberCount})
    }

};

const updateWebSocketData = (newData) =>{
    let currentStore = store.getState();
    let wsData = Object.assign(currentStore.webSocketData,newData);
    let action = updateWebSocket(wsData);
    store.dispatch(action);
};

const setClientid = (newid,props) => {
    updateWebSocketData({clientid:newid});
    clientId=newid;
    //let newwebsocket=Object.assign({},props?.appSettings?.websocket,{clientid:newid,chatchannel:chatChannel});
    //props?.updateWebSocket(newwebsocket);
};

const openHandler = (evt) => {
    updateWebSocketData({status:"open"});
    //console.dir("Web Socket Open");
    //console.dir(evt);
};

const openClose= (evt) => {
    updateWebSocketData({status:"closed"});
    //console.dir("Web Socket Closed");
    //console.dir(evt);

};

const errorHandler = (evt) => {
    console.dir("ERROR MESSAGE");
    console.dir(evt);
    //console.dir("Error Handler")
    //console.dir(evt);

};
