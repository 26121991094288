import React from 'react';
import {connect} from "react-redux";
import {updateAppSettings} from "./actions/foundationActions";
import Container from 'react-bootstrap/Container';
import {URLTokenInterpreter} from "./components/gametime/common/URLTokenInterpreter";
import {updateGameTime,updateCurrentActivityData} from "./actions/gameTime";
import {updateUser} from './actions/permissionsActions'
import {updateTesting} from "./actions/testingActions";
import {obtainMediaTypes} from "./services/APIServices";
import GenericModalDefault from "./components/supporting/GenericModal/GenericModalDefault";

class App extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            currentModule:null
        }
    }

    componentDidMount() {
        this.loadNewMod("components/gametime/Welcome/Welcome");
        obtainMediaTypes(this.props);
        URLTokenInterpreter(this.props);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.appSettings.currentModule !== prevProps?.appSettings?.currentModule){
            this.loadNewMod(this.props.appSettings.currentModule)
        }

    }

    render(){
        let moduleShow = this.state.currentModule ? <this.state.currentModule /> : null;

        return (<Container fluid style={{border:"thin solid red",height:"100%"}} id="appWrapper">
            <GenericModalDefault triggerName="showDefaultModal"/>
            {moduleShow}
    </Container>)
    }

    loadNewMod(mod,url){
        import( './' + mod  ).then(module => {
            this.setState({currentModule:module.default});
        });
    }

}


const mapStateToProps = (state) =>{
    return {
        appSettings:state.appSettings,
        user:state.user
    };
};

export default connect(mapStateToProps,{
    updateAppSettings:updateAppSettings,
    updateGameTime,
    updateUser,
    updateCurrentActivityData,
    updateTesting
})(App);
