import React from 'react';
import ReactDOM from 'react-dom';
//import {createStore} from 'redux';
import {Provider} from 'react-redux';
import App from './App';
//import reducers from './reducers/index';
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'
import store from './store'
//const store = createStore(reducers);

const localArr = [
    "localhost",
    "127.0.0.1"
]

const correctLocation = () =>{
    //eslint-disable-next-line
    if(location.protocol.substr(0,5)!=='https' && localArr.indexOf(location.hostname) ===-1){
        //eslint-disable-next-line
        location.href="https://" + location.hostname + ':' + location.port;
    }
};

correctLocation();



ReactDOM.render(
    <Provider store={store}>
        <DndProvider backend={Backend}>
            <App />
        </DndProvider>
    </Provider>, document.getElementById('root'));



