export const updateAppSettings = (status) =>{

    return {
        type:"UPDATE_APP_SETTING",
        payload:status
    }
};

export const updateDataStore = (status) => {
    return {
        type:"UPDATE_DATA_STORE",
        payload:status
    }
};
