import React, {useEffect, useRef} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {connect} from "react-redux";
import {updateAppSettings} from "src/actions/foundationActions";

const loadNewMod = (props,mod,previewMedia) => {
    import( 'src/' + mod  ).then(module => {
        props.updateAppSettings({modalModule:module.default});
    });
};

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const GenericModal  = (props) => {
    let currentModName = props.appSettings.modalModuleName;
    let prevModName = usePrevious(props.appSettings.modalModuleName);
    let modalTitle= props.appSettings?.modalTitle ? props.appSettings.modalTitle : null;
    if(currentModName !== prevModName){
        loadNewMod(props,currentModName);
    }

    let modMod=props.appSettings.modalModule ? <props.appSettings.modalModule /> : null;

       let dismisser={};
        dismisser[props.triggerName]=false;
        return(
            <Modal id="genericModal" show={props.appSettings[props.triggerName]} onHide={() => props.updateAppSettings(dismisser)}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modMod}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() =>{props.updateAppSettings(dismisser)}}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        )
};

const mapStateToProps = (state) =>{
    return  {
        appSettings:state.appSettings,
    };
};

export default connect(mapStateToProps,{
    updateAppSettings
})(GenericModal);
