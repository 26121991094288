// Updates Game Time
export const updateGameTime = (update) =>{
        return {
        type:"UPDATE_GAME_TIME",
        payload:update
    };
};

//Updates GameTimeSession
export const updateGameTimeSession = (update) => {
    return {
        type:"UPDATE_GAMETIME_SESSION",
        payload:update
    }
};
/*
export const updateHostData = hostData =>{
    return {
        type:"HOST_INFO",
        payload:hostData
    }
};
*/

//Updates the props.teamData - used locally and broadcast to host
export const updateTeamData = update =>{
    return {
        type:"UPDATE_TEAMDATA",
        payload:update
    };
};

export const updateTeamMembers = (update) =>{
    let resp={name:update};
    return {
        type:"UPDATE_TEAMMEMBER",
        payload:resp
    };
};

//Requests a Web Socket upDate (such as when a guest joins the network)
export const requestSocketUpdate = (update) =>{
    return {
        type:"WEBSOCKET_UPDATE",
        payload:update
    }
};
//Updates the props.currentActivity
export const updateCurrentActivity = (update) =>{
    return {
        type:"UPDATE_CURRENT_ACTIVITY",
        payload:update
    }
};

//Updates the props.currentActivityData - The authoratative Object of the activity state
export const updateCurrentActivityData = (update) =>{
    return {
        type:"UPDATE_CURRENT_ACTIVITY_DATA",
        payload:update
    }
};


/********** Teams **********/
//Submit Team For Approval
export const registerTeamName = (team) =>{
    return {
        type:"ADD_TEAM_TO_ACTIVITY",
        payload:team
    };
};
//The list of teams waiting to be approved
export const updateNewTeamList = (newList) =>{
    return{
        type:"UPDATED_NEW_TEAM_LIST",
        payload:newList
    }
};
//Team Approved
export const submitTeamForApproval = (team) => {
    return {
        type:"SUBMIT_TEAM_FOR_APPROVAL",
        payload:team
    }
};
//Team Not Approved
export const clearSubmitTeamForApproval = (team) => {
    return {
        type:"REMOVE_SUBMIT_TEAM_FOR_APPROVAL",
        payload:team
    }
};
//Adds the Team to the team Obj (with teaid as key. Will overwrite changes from that teamid
export const updateCurrentActivityDataTeams = (update) =>{
    return {
        type:"UPDATE_CURRENT_ACTIVITY_DATA_TEAMS",
        payload:update
    }
};


/******** Responses *******/

//Register a Response From Team
export const registerActivityAnswer = (answer) =>{
    return {
        type:"ACTIVITY_REMOTE_RESPONSE",
        payload:answer
    }
};

//Clear the last response from team
export const clearActivityResponse = () =>{
    return {
        type:"CLEAR_ACTIVITY_RESPONSE"
    }
};


//Add a Response to be considered
export const addPotentialResponse = (answer) =>{
    return {
        type:"ADD_RESPONSE_TO_BE_PROCESSED",
        payload: answer
    }
};

export const updatePotentialResponse = (allPotentials) =>{
    return {
        type:"REPLACE_POTENTIAL_RESPONSES",
        payload:allPotentials
    }
};




//Used to add an activity response to be reviewed
export const updateActivityResponse = (answer) => {
    console.dir("Updating activity response");
    console.dir(answer);
    return {
        type:"ACTIVITY_RESPONSE",
        payload:answer
    }
};




