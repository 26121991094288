export const visualizationModel = () => {
    return {
        "mainVisualizerModName":"",
        "mainVisualizerMod":null,
        "allProjects":null,
        "currentProject":null,
        "allFiles":[],
        "fileDict":{},
        "projectStats":{},
            "currentDependent":"",
            "depOrder":"down",
            "filesOnly":true,
            "runJSTests":false,
            "sortedTops":{
                "Page":[],
                "Event":[],
                "Class":[],
                "Method":[],
                "Component":[],
                "Function":[],
                "Action":[],
                "Misc":[]
            },

            "allMapping":{},
            "openAll":true,
            "recurse":false,
            "displayMode":"audit",
            "onhold":false,
            "currentModule":null,
            "currentSubAsString":"VisualizationHierarchy",
            "projectNumber":0

    }
};
