export const lowerCaseKeys = ( data )=>{
    if (Array.isArray( data )){
        let length = data.length;
        let tempArray = [];

        while (length--){
            tempArray[length] = lowerCaseKeys(data[length]);
        }

        return tempArray;
    } else if (typeof data === 'object' && data !== null){

        let keyList = Object.keys(data);
        let amountOfKeys = keyList.length;
        let newObject = {};

        while( amountOfKeys-- ){
            let key = keyList[amountOfKeys];
            let newKey = key.toLowerCase();
            newObject[newKey] = data[key];

            if( Array.isArray( newObject[newKey] ) || typeof newObject[newKey] === 'object') {
                newObject[newKey] = lowerCaseKeys(newObject[newKey]);
            }
        }

        return newObject;

    } else {
        return data;
    }
};

export const queryToJSON=(q)=>{
    console.dir(q);
    if(q && (q.hasOwnProperty("DATA") || q.hasOwnProperty("data"))) {
        let allData = q.hasOwnProperty("DATA") ? q.DATA : q.data;
        console.dir(allData);
        //let allColumns = q.hasOwnProperty("COLUMNS") ? q.COLUMNS : q.columns;
        //let retme = allData?.map((item) => {

            //let reme = {};
//            for (let x = 0; x < allColumns.length; x++) {
  //              reme[allColumns[x]] = item[x]
    //        }
      //      return reme;
        //});
        //if (retme) {
        //    return retme;
        //} else {
        //    return []
        //}
    }
    else{
        console.dir("There was no data submitted to queryToJson");
    }
}

export const parseInitials = (texter) =>{
    let names=texter.split(" ");
    return (names.map((item)=>{
            return item.substring(0,1).toUpperCase()
    })).toString().replace(/,/g,'');
};


