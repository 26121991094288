import React from "react";

export const gameTimeDataModel = () => {
    return {
        "defaultModule": "components/gametime/Welcome/Welcome",
        "defaultLoggedInModule": "components/gametime/MyGameTime/MyGameTime",
        "layout": {
            "activityWidth": 6,
            "chatWidth": 6
        },
        "currentActivity": null,
        "currentActivityName": "",
        "myRoomNavs": [],
        "availableActivities":activityOptions,
        "activityDict":activityDict()
    }
};

export const gameTimeSession= () => {
    return {
        "emceeSocket": 0,
        "hostSocket": 0,
        "teams": {},
        "host": {
            "name": ""
        }
    }
};


const activityDict = () =>{
    let retme={};
    //eslint-disable-next-line
    activityOptions.map((item)=>{
        retme[item.activityCode]=item;
    });
    return retme;
};

const activityOptions=[
    {"class":"components/gametime/PlayList/PlayList","name":"PlayList","activityCode":"playlist","active":true,"hostClass":"components/gametime/PlayList/PlayListHost"},
    {"class":"components/gametime/Teams/TeamSignup","name":"Team Signup","activityCode":"teamSignup","active":true,"hostClass":""},
    {"class":"components/games/Celebrity/Celebrity","name":"Celebrity","activityCode":"celebrity","active":true,"hostClass":"components/games/Celebrity/CelebrityHost"},
    {"class":"components/games/Dictionary/Dictionary","name":"Dictionary","activityCode":"dictionary","active":true,"hostClass":"components/games/Dictionary/DictionaryHost"},
    {"class":"components/games/Trivia/Trivia","name":"Trivia","activityCode":"trivia","active":true,"hostClass":"components/games/Trivia/TriviaSettings"},
    {"class":"components/media/URLShare/URLShare","name":"URL Share","activityCode":"urlshare","active":true,"host":null},
    {"class":"components/readToMe/ReadToMe","name":"Read To Me","activityCode":"readtome","active":true,"hostClass":"components/readToMe/ReadToMeHost"},
    {"class":"components/games/Matching/Matching","name":"Matching","activityCode":"matching","active":true,"hostClass":null}
];
