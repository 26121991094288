import axios from "axios";
import {lowerCaseKeys, queryToJSON} from "../utilities/utilityFunctions";
import {createDictionary} from "../components/gametime/common/gameTimeFunctions";

const apiURL = (props) =>{
    //eslint-disable-next-line
    return props?.appSettings?.apiURL?.hasOwnProperty(self.location.hostname) ? props?.appSettings.apiURL[self.location.hostname] : '';
};

const makeFormData = (data) => {
    let former=new FormData();
    for(var x in data){
        former.append(x,data[x]);
    }
    return former;
};

const obtainUser = (props)=>{
    return props.user ? props.user.token : '';
};

export const obtainMediaTypes = (props) => {
    axios.get(apiURL(props) + "/gametime/mediatypes").then((evt)=>{
        let res=lowerCaseKeys(evt.data);
        let typeDict=createDictionary(props,res,"mediatypeid");
        props.updateGameTime({"mediaTypes":res,"mediaTypeDict":Object.assign({},typeDict)});
    }).catch((evt)=>{
        console.dir("back fail");
        console.dir(evt);
    });
};

export const decodeToken = (props,token,callBack,fail) => {
    axios.get(apiURL(props) + "/invitations/decode/" + token).then((evt)=>callBack(props,evt)).catch((evt)=>fail(props,evt));
};

export const login = (props,data,callBack,Fail) => {
    let formDataStuff = makeFormData(data);
    axios.post(apiURL(props) + "/users/login",formDataStuff).then((evt)=>callBack(props,evt)).catch((evt)=>Fail(props,evt));
};

export const obtainMyLists =(props) => {
    axios.get(apiURL(props) + "/users/lists",{
        headers: {
            "Authorization": "Bearer " + obtainUser(props)
        }
    }).then((evt)=>{
        let lists=lowerCaseKeys(evt.data);
        let listDict=createDictionary(props,lists,"mylistid");
        props.updateGameTime({myLists:lists,myListDict:listDict});
    }).catch((evt)=>{
        console.dir("Fail lists");
        console.dir(evt);
    });
};

export const saveList = (props,formdata,callBack,fail) => {
    axios.post(apiURL(props) + "/media/list/save",makeFormData({list:JSON.stringify(formdata)}),{headers: {
            "Authorization": "Bearer " + obtainUser(props)
        }}).then((evt)=>callBack(props,evt)).catch((evt)=>fail(props,evt));
};

/*
export const saveList =(props,formData,)=> {
    let noder = document.querySelector("#uploadBookData");
    const formData = new FormData();
    if(noder && noder.files && noder.files.length && noder.files[0].name) {
        formData.append(
            "uploadBook",
            noder.files[0],
            noder.files[0].name
        );
    }
    formData.append("user", props.user.personuuid);
    formData.append("list", props.dataStore.gameTimeListEdit.mylistid);
    formData.append("mylistname", props.dataStore.gameTimeListEdit.mylistname,);
    formData.append("mylistactive", props.dataStore.gameTimeListEdit.mylistactive === 'on' ? 1 : 0);
    formData.append("mylistdesc", props.dataStore.gameTimeListEdit.mylistdesc);
    formData.append("listfiletype", props.dataStore.gameTimeListEdit.listfiletype);
    console.dir(formData);
    let prologue=apiURL(props);
    //let useURL=props?.appSettings?.URLStruct?.hasOwnProperty(self.location.hostname) ? props?.appSettings.URLStruct[self.location.hostname] : '';
    axios.post(prologue + "/media/list/save", formData).then((evt) => {
        console.dir(evt);
        //if(evt.success===200){
        //    updateMyMedia(props);
        //}
    }).catch((evt) => console.dir(evt));
}
*/
export const obtainMyRooms = (props,callBack,fail) => {
    axios.get(apiURL(props) + "/users/rooms",{headers: {
            "Authorization": "Bearer " + obtainUser(props)
        }}).then((evt)=>callBack(props,evt)).catch((evt)=>fail(props,evt));
};

export const obtainMySettings = (props,callBack,fail) => {
    axios.get(apiURL(props) + "/users/settings",{headers: {
            "Authorization": "Bearer " + obtainUser(props)
        }}).then((evt)=>callBack(props,evt)).catch((evt)=>fail(props,evt));
};

export const obtainMyMedia = (props,callBack,fail) => {
    axios.get(apiURL(props) + "/media/",{headers: {
            "Authorization": "Bearer " + obtainUser(props)
        }}).then((evt)=>callBack(props,evt)).catch((evt)=>fail(props,evt));
};

export const obtainMyMediaByList = (props,listid, callBack,fail) => {
    //let lister=listid ? listid : props.gameTime?.chosenList ? props.gameTime.chosenList : '';
    axios.get(apiURL(props) + "/media/listMedia/" + listid,{headers: {
            "Authorization": "Bearer " + obtainUser(props)
        }}).then((evt)=>callBack(props,evt)).catch((evt)=>fail(props,evt));
};

export const loadMediaByType = (props,types,callBack,fail) => {
    axios.post(apiURL(props) + "/media/filter",makeFormData({types:types}),{"headers": {
            "x-Authorization": "Bearer " + obtainUser(props)
        }}).then((evt)=>{callBack(props,evt)}).catch((evt)=>{fail(props,evt)});
};

export const uploadNewQuizRunner = (props,formData,callBack,fail) => {
    axios.post(apiURL(props) + "/media/quizRunner",formData,{headers: {
        "Authorization": "Bearer " + obtainUser(props),
            'Content-Type': 'multipart/form-data'
    }}
    ).then((evt)=>callBack(props,evt)).catch((evt)=>fail(props,evt));
};

export const obtainListData= (props,listid, callBack,fail) => {
    //let lister=listid ? listid : props.gameTime?.chosenList ? props.gameTime.chosenList : '';
    if(listid) {
        axios.get(apiURL(props) + "/media/listData/" + listid, {
                headers: {
                    "Authorization": "Bearer " + obtainUser(props),
                }
            }
        ).then((evt) => callBack(props, evt)).catch((evt) => fail(props, evt));
    }
    else{
        console.dir("No list submitted to obtain ListData");
    }
};

export const obtainMyConnections = (props,callBack,fail) => {
    axios.get(apiURL(props) + "/media/listMedia",{headers: {
            "Authorization": "Bearer " + obtainUser(props),
        }}
    ).then((evt)=>callBack(props,evt)).catch((evt)=>fail(props,evt));
};

export const saveNewConnection = (props,data,callBack,fail) => {
    axios.post(apiURL(props) + "/users/saveConnection",makeFormData(data),{headers: {
            "Authorization": "Bearer " + obtainUser(props),
        }}
    ).then((evt)=>callBack(props,evt)).catch((evt)=>fail(props,evt));
}
