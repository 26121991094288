import {visualizationModel} from '../datamodels/visualizations';

const visualizationReducer = (state=visualizationModel(),action) => {
    if (action.type === 'UPDATE_VISUALIZER') {
        return  Object.assign({}, state, action.payload);
    }
    return state;
};




export default {
    visualizer:visualizationReducer
}
