import {eyesOnDataModel} from 'src/datamodels/eyesOnData';

export const updateEyesOnData = (eyeson=eyesOnDataModel(),action) =>{
    if(action.type==='UPDATE_EYESON'){
        let eyesData = Object.assign({},eyeson,action.payload);
        eyesData.guest_key = action?.payload?.links?.guest_join?.split('=')[1];
        return eyesData;
    }
    return eyeson;
};

/*
export const broadcastEyesOnUpdateReducer = (eyeson = {increment:0},action) =>{
    if(action.type==='BROADCAST_EYESON'){
        //console.dir("broadcastingthe eyes on reducer");
        //console.dir(eyeson);
        var newEyes=Object.assign({},eyeson);
        newEyes.room = Object.assign({},action.payload);
        newEyes.increment++;
        //console.dir("newEyes");
        //console.dir(newEyes);
        return newEyes;
    }
    return eyeson;
};
*/
export default {
    eyesOnData:updateEyesOnData
}
