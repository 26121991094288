import {appSettingModel} from "../datamodels/foundation";

const appSettingsReducer = (state=appSettingModel(),action) => {
    if (action.type === 'UPDATE_APP_SETTING') {
        return  Object.assign({}, state, action.payload);
    }
    return state;
};

const dataStoreReducer = (state = {},action)=>{
    if(action.type==='UPDATE_DATA_STORE'){
        return Object.assign({},state,action.payload);
    }
    return state;
};


export default {
    appSettings:appSettingsReducer,
    dataStore:dataStoreReducer
}
