import {websocketModels} from '../datamodels/webSocketModels'

export const updatewebSocketReducer = (state=websocketModels(),action) =>{

    if(action.type==="UPDATE_WEB_SOCKET"){
        return Object.assign({},state,action.payload);
    }

    return state;
};

/*
import {websocketModels} from '../datamodels/webSocketModels'

export const updatewebSocketReducer = (state=websocketModels(),action) =>{

    if(action.type==="UPDATE_WEB_SOCKET"){
        let newAppsettings= Object.assign({},state);
        newAppsettings = Object.assign({},action.payload);
        return newAppsettings;
    }

    return state;
};

export const updateWebSocketListenersReducer = (listeners={},action) => {
  if(action.type==='ADD_WEB_SOCKET_LISTENER'){
      let allListeners = Object.assign({},listeners);
      if(allListeners.hasOwnProperty(action.payload.type)){
          let newInd = allListeners[action.payload.type].push(action.payload);
      }
      else{
          allListeners[action.payload.type]=[action.payload];
      }
      return allListeners;
  }
  return listeners;
};
*/


export default {
    webSocketData:updatewebSocketReducer
 //   socketListeners:updateWebSocketListenersReducer
}
