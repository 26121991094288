export const updateUser = (action)=>{
    return {
        type: "UPDATE_CURRENT_USER",
        payload: action
    }
};

export const logoutUser = (action)=>{
    console.dir("in logout user action");
    return {
        type:"LOGOUT_USER",
        payload:{isLoggedIn:false}
    }
}
