import {combineReducers, compose,applyMiddleware} from 'redux';
import foundationReducers from './foundationReducers';
import permissionsReducers from './permissionsReducers';
import gameTimeReducers from './gameTimeReducers';
import websocketReducers from './websocketReducers';
import eyesonReducers from './eyesonReducers';
import visualizationReducers from './visualizationReducers'
import testingReducers from './testingReducers';
import {reducer as SWRTCReducer} from "@andyet/simplewebrtc";

import Thunk from 'redux-thunk';

const allReducers=Object.assign({},foundationReducers,permissionsReducers,gameTimeReducers,websocketReducers,eyesonReducers,visualizationReducers,testingReducers,{simplewebrtc:SWRTCReducer});
compose(applyMiddleware(Thunk));
export default combineReducers(allReducers);
