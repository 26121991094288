import {gameTimeDataModel} from '../datamodels/gameTime';
import {gameTimeSession} from '../datamodels/gameTime';
import {isTeamAdded} from 'src/components/gametime/common/gameTimeFunctions';
import store from 'src/store';

const gameTimeReducer = (state=gameTimeDataModel(),action) => {
    if (action.type === 'UPDATE_GAME_TIME') {
        return  Object.assign({}, state, action.payload);
    }
    return state;
};

export const gTSessionReducer = (state=gameTimeSession(),action) =>{
    if(action.type==='UPDATE_GAMETIME_SESSION'){
        return Object.assign({},state,action.payload);
    }



    if(action.type==='UPDATE_TEAMMEMBER'){
        let newTeamMembers = Object.assign({},state);
        if(!newTeamMembers.team.members){ newTeamMembers.team.members=[]}
        newTeamMembers.team.members.push(action.payload.name);
        return newTeamMembers;
    }

    return state;
};

export const potentialResponsesReducer = (state=[],action) => {
    if(action.type==='ADD_RESPONSE_TO_BE_PROCESSED'){
        console.dir("new potential!");
        let newState=[...state];
        newState.push(action.payload);

        console.dir(newState);
        return newState;
    }

    if(action.type==='REPLACE_POTENTIAL_RESPONSES'){
        return action.payload;
    }

    return state;
};

export const teamDataReducer = (state = {},action) =>{
    if(action.type==='UPDATE_TEAMDATA'){
        return Object.assign({},state,action.payload);
        //newTeams[action.payload.teamid]=action.payload;
        //return newTeams;
    }

    if(action.type==='UPDATE_TEAMMEMBER'){
        let newTeamMembers = Object.assign({},state);
        if(!newTeamMembers.team.members){ newTeamMembers.team.members=[]}
        newTeamMembers.team.members.push(action.payload.name);
        return newTeamMembers;
    }
    return state;
};

let initialHostData ={name:"",socket:""};
export const hostDataReducer = (state=initialHostData,action) =>{
    if(action.type==='HOST_INFO'){
        let newState=Object.assign({},state,action.payload);
        return newState;
    }
    return state;
};

export const webSocketUpdateReducer=(state={},action) =>{
  if(action.type==='WEBSOCKET_UPDATE'){
      let newUpdateRequest=Object.assign({},state,action.payload);
      return newUpdateRequest;
  }
  return state;
};

export const currentActivityReducer=(state={},action) =>{
    if(action.type==='UPDATE_CURRENT_ACTIVITY'){
        return Object.assign({},state,action.payload);
    }
    return state;
};

//Merges currentActivityData and
export const currentActivityDataReducer = (state={teams:{}},action) => {
    if(action.type==='UPDATE_CURRENT_ACTIVITY_DATA'){
        return Object.assign({},state,action.payload);
    }

    if(action.type==="UPDATE_CURRENT_ACTIVITY_DATA_TEAMS"){
        let newState=Object.assign({},JSON.parse(JSON.stringify(state)));
        newState.teams[action.payload.teamid] = action.payload;
        return newState;
    }
    return state;
};

const teamAlreadyAdded = (team,waitingTeams) => {
    return waitingTeams.hasOwnProperty(team.teamid)
};

export const newTeamToAddReducer = (state=[],action)=>{
    switch (action.type){
        case "ADD_TEAM_TO_ACTIVITY":
            console.dir("Adding in reducer");
            console.dir(action);
            let newTeams = [...state];
            newTeams.push(action.payload);
            return newTeams;
        case "UPDATED_NEW_TEAM_LIST":
            let newList =[...action.payload];
            return newList;
        default:
            return state;
    }
};

export const teamJoinRequest = (state={},action)=>{
    if(action.type==='SUBMIT_TEAM_FOR_APPROVAL'){
        return action.payload;
    }
    if(action.type==="REMOVE_SUBMIT_TEAM_FOR_APPROVAL"){
        return {};
    }
    return state;
};

export const activityResponse = (state={},action) => {

    if(action.type ==='CLEAR_ACTIVITY_RESPONSE'){
        return [];
    }
    if(action.type==='ACTIVITY_RESPONSE'){
        return Object.assign({},state,action.payload);
    }
    return state;
};



export default {
    gameTime:gameTimeReducer,
    currentActivityData:currentActivityDataReducer,
    potentialResponses:potentialResponsesReducer,

    teamData:teamDataReducer,
    webSocketUpdateRequests:webSocketUpdateReducer,
    currentActivity:currentActivityReducer,


    gameTimeSession:gTSessionReducer,
    //activityRemoteResponses:remoteResponse,
    //remoteResponse:remoteResponse,
    teamsToAdd:newTeamToAddReducer,
    activityResponse:activityResponse,
    submitTeamForApproval:teamJoinRequest
}
