import {connect} from "react-redux";
import {updateAppSettings} from "../../../actions/foundationActions";
import {updateUser} from "../../../actions/permissionsActions";
import {updateGameTime,updateCurrentActivityData} from "../../../actions/gameTime";
import {obtainURLParam} from './gameTimeFunctions';
//import CallKim from "../../../services/CallKim";
import {lowerCaseKeys} from "../../../utilities/utilityFunctions";
import {updateTesting} from "../../../actions/testingActions";
import {decodeToken} from "../../../services/APIServices";

export const URLTokenInterpreter = (props) =>{
    let token = obtainURLParam("gtt");
    if(token){
        decodeToken(props,token,processDecode,failDecode);
    }
    let testMode=obtainURLParam("testMode");
    if(testMode){
        props.updateAppSettings({testMode:true});
    }
    let randomLoad = obtainURLParam("randLoad");
    console.dir(randomLoad);
    if(randomLoad){
        props.updateAppSettings({currentModule:"components/Testing/RandomLoad"});
        props.updateTesting({"randomLoadModName":randomLoad});
    }
};
/*
const decodeToken = (props,token,processDecode,failDecode) => {
    CallKim(props,
        "ptkim.com.purposefultechnologies.kim.gametime.InvitationInterface",
        "decodeToken",
        {token:token},
        (evt)=>processDecode(props,evt),
        (evt)=>failDecode(props,evt)
    );
};
*/
const processDecode = (props,evt)=>{
    if(evt.data.data.appSettings){
        props.updateAppSettings(evt.data.data.appSettings);
    }
    if(evt.data.data.gameTime){
        props.updateGameTime(evt.data.data.gameTime);
    }
    if(evt.data.data.currentActivityData){
        props.updateCurrentActivityData(Object.assign({},evt.data.data.currentActivityData))
    }
    if(evt.data.data.user){
        let person=Object.assign({},lowerCaseKeys(evt.data.data.user));
        person.isLoggedIn=true;
        props.updateUser(person);
    }

    console.dir(evt)
};

const failDecode = (props,evt) => {
    console.dir(evt);
};

const mapStateToProps = (state) =>{
    return {
        appSettings:state.appSettings,
        dataStore:state.dataStore,
        gameTime:state.gameTime,

    };
};

export default connect(mapStateToProps,{
    updateAppSettings:updateAppSettings,
    updateUser:updateUser,
    updateGameTime,
    updateCurrentActivityData,
    updateTesting
})(URLTokenInterpreter);
