import {lowerCaseKeys, queryToJSON} from "src/utilities/utilityFunctions";
import {obtainMyRooms} from "src/services/APIServices";
import {obtainMySettings,obtainMyMedia} from "src/services/APIServices";
import {obtainMyMediaByList,obtainListData} from "src/services/APIServices";
import {createWebSocket} from "../../websockets/SocketRouter";
import {apiURL} from "../../visualizations/APIServices";


/********* State and Roles  **********/
export const obtainURLParam = (paramName) => {
        let query = window.location.search.substring(1);
        let vars = query.split("&");
        for (let i=0;i<vars.length;i++) {
            let pair = vars[i].split("=");
            if(pair[0] === paramName){return pair[1];}
        }
        return(false);
};

export const isLoggedIn = (props) => {
    return props.user?.isLoggedIn && props.user?.isLoggedIn !== '';
};

export const isCurrentHost = (props) =>{
    return props.currentActivityData ?
            props.currentActivityData.hostSocket && props.currentActivityData.hostSocket === props.webSocketData.clientid
            : false
};

export const isCurrentEmcee = (props) =>{
    return props.currentActivityData.emceeSocket && props.currentActivityData.emceeSocket === props.webSocketData.clientid;
};

export const isGuestOnly = (props) => {
    return props.currentActivityData.hostSocket && props.currentActivityData.hostSocket !== props.webSocketData.clientid &&
        props.currentActivityData.emceeSocket && props.currentActivityData.emceeSocket !== props.webSocketData.clientid
};



/******* Settings *******/
export const updateMySettings = (props) => {
    obtainMySettings(props,processSettings,failSettings);
};

const processSettings = (props,evt)=>{
    let res=lowerCaseKeys(evt.data.data);
    props.updateGameTime({mySettings:res,mySettingsDict:createSettingsDict(res)});
};

const createSettingsDict = (settingsArray) =>{
    let retme={};
    //eslint-disable-next-line
    settingsArray.map((item)=>{
        retme[item.settingscode] = item.hasOwnProperty("settingscode") ? item.mysettingsvalue : null;
    });
    return retme
};

const failSettings = (props,evt)=>{
    console.dir("Fail Settings");
    console.dir(evt);
};

export const obtainSettingsValue = (props,settingcode) =>{
    return  props.gameTime && props.gameTime.mySettingsDict && props.gameTime.mySettingsDict[settingcode]  ? props.gameTime.mySettingsDict[settingcode]: null
};

export const establishDefaultRoom = (props) =>{
    let roomDict = props.gameTime.myRoomDict;
    let defRoom = obtainSettingsValue(props,"defRoom");
    if(defRoom && roomDict){
        roomDict["default"] = Object.assign({},roomDict[defRoom]);
    }
    props.updateGameTime({myRoomDict:roomDict});
};



/********* Rooms ************/
export const updateMyRooms = (props) => {
    if(props.user.token) {
        obtainMyRooms(props,processRooms, failRooms);
    }
};

export const assembleRooms = (personal, invited) =>{
    return ([])
        .concat([{myroomname:"--- My Rooms --- "},...personal])
        .concat([{myroomname:"--- Invited Rooms --- "},...invited]);
};

export const createRoomDicts = (allRooms) => {
    let roomDict={};
    //eslint-disable-next-line array-callback-return
    allRooms.map((item)=>{
        roomDict[item.roomid] = item;
    });
    return roomDict;
};

export const processRooms = (props, e) => {
    //eslint-disable-next-line array-callback-return
    let allRooms = e.data.data;
    let roomDict=createRoomDicts(allRooms);
    props.updateGameTime({myRooms:allRooms,myRoomDict:roomDict});
};

export const failRooms = (props,e) => {
    console.dir("back fail rooms");
    console.dir(e);
};

export const obtainRoomDetails = (props,roomid) =>{
    return props.gameTime?.myRoomDict?.hasOwnProperty(roomid) ? props.gameTime.myRoomDict[roomid] : {};
};

export const obtainDefaultRoom = (props) => {
    return props.gameTime.myRoomDict && props.gameTime.myRoomDict.default ? props.gameTime.myRoomDict.default : null;
};

/******* Media ********/
export const updateMyMedia = (props) =>{
    obtainMyMedia(props,processMyMedia, failMedia);
};

const processMyMedia = (props,e) => {
    let returnedMedia = lowerCaseKeys(e.data);
    let dict=createDictionary(props,returnedMedia,"mymediaid");
    props.updateGameTime({myMedia:returnedMedia,myMediaDict:dict});
};

const failMedia= (e) => {
    console.dir("fail");
    console.dir(e);
};

export const extractThumbnail = (props,item) =>{
    switch (item.mymediatype) {
        case "4CD5CAFD-E0A5-4044-A1110E4F4312D0B0":
            return item.mymediathumbnail;
        default:
            return apiURL(props) + "/media/servThumbnail?media=" + item.mymediaid;
    }

};




/********* Media Types **********/
export const createDictionary=(props,data,key) =>{
    let typeDict={};
    //eslint-disable-next-line array-callback-return
    data.map((item)=>{
        typeDict[item[key]] = item;
    });
    return typeDict;
};

/***************** Lists and List Items *******************/
export const updateListElements = (props,listid) =>{
    console.dir("update list elements");
    console.dir(listid);
    //listid=listid ? listid : props.gameTime.chosenList;
    if(listid) {
        updateListItems(props, listid);
        updateListData(props, listid);
    }

};

export const updateListData=(props,listid)=>{
    //listid=listid ? listid : props.gameTime?.chosenList;
    if(props.user.hasOwnProperty("token") && listid) {
        obtainListData(props,listid,processListData,failListData);
        /*CallKim(props,
            "ptkim.com.purposefultechnologies.kim.gametime.mediaInterface",
            "listData",
            {owner: personid, listid: listid},
            (evt) => processListData(props, evt),
            (evt) => failListData(props, evt),
            props.user
        );*/
    }
};

export const processListData = (props,e) => {
    console.dir("Back from list data");
    console.dir(e);
    console.dir(e.data);
    let listData=lowerCaseKeys(e.data);
    console.dir("post querytojson");
    console.dir(listData);
    props.updateGameTime({currentList:listData.length ? listData[0] : {}})
};

export const failListData = (props,e) =>{
    console.dir("fail lists");
    console.dir(e);
};

export const updateListItems = (props,listid) =>{
    //personid = personid ? personid : props.user.personuuid;
    //listid=listid ? listid : props.updateGameTime?.chosenList;
    if(props.user.hasOwnProperty("token")) {
        obtainMyMediaByList(props,listid,processListItems,failListItems);
        /*
        CallKim(props,
            "ptkim.com.purposefultechnologies.kim.gametime.mediaInterface",
            "myMediaToList",
            {listid: listid},
            (evt) => processListItems(props, evt),
            (evt) => failListItems(props, evt),
            props.user
        );

         */
    }
};

export const processListItems=(props,e)=>{
    console.dir("ack from getting list byu media");
    let listitems=lowerCaseKeys(e.data);
    console.dir(listitems);
    props.updateGameTime({currentListItems:listitems});
};

export const failListItems = (props,e) =>{
    console.dir("fail list");
    console.dir(e);
};




/********** Teams *********/
export const isTeamAdded = (props,team) =>{
    let id = team?.teamid ? team.teamid : props.webSocketData.clientid;
    return props.currentActivityData.teams.hasOwnProperty(id)
};


export const createWebSocketSingleton = (props) => {
    console.dir("Creating Web Socket Singleton");
    props.updateWebSocket({websocket:createWebSocket(props),socketStarted:true});
};


export const changeActivity = (props,activityModule,hostModule=null,media=null) => {
    let activeRoom = props.gameTime.myRoomDict[obtainSettingsValue(props,"defRoom")];
    props.updateGameTime({activeRoom:activeRoom});
    props.updateCurrentActivityData({currentActivityName:activityModule,activeMedia:media});
    props.updateAppSettings({gameTimeMainModule:"components/gametime/MyRoom/MyRoom",myRoomModalComponentName:hostModule});
};
